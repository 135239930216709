import { LoadingOverlay, Stack } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import ReportFooter from './components/ReportFooter'
import { useNavigate, useParams } from 'react-router-dom'
import { report } from '../api/orderService'
import { getModuleNodes } from './components/ModuleUtil'
import FloatingButton from '../components/FloatingButton'
import RightFloating from '../components/RightFloating'
import { Dialog } from 'antd-mobile'
import Share from '../components/Share'
import Watermark from '@uiw/react-watermark'

export default function Report() {
  const navigate = useNavigate()
  let { orderId } = useParams()
  const [data, setData] = useState<Map<String, any>>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [shareVisible, setShareVisible] = useState<boolean>(false)
  useEffect(() => {
    if (orderId === '') {
      navigate('/')
    }
    const fetchData = async (retryDelays = [3000, 2000, 2000, 3000, 5000, 5000, 5000], attempt = 0) => {
      report(orderId!)
        .then((res) => {
          setData(res.data)
          setIsLoading(false)
        })
        .catch((error) => {
          if (error.errorCode === 1011) {
            if (attempt < retryDelays.length) {
              setTimeout(() => fetchData(retryDelays, attempt + 1), retryDelays[attempt])
            } else {
              Dialog.alert({
                content: '报告生成中，请返回报告列表手动刷新查看或者联系客服处理。',
                onConfirm: () => {
                  navigate('/orders')
                },
              })
            }
          }
        })
    }
    fetchData().then()
  }, [navigate, orderId])

  return (
    <Watermark
      content={['大数据报告仅作参考', '不提供贷款等金融服务', '非央行征信报告']}
      style={{ background: '#fff' }}
    >
      <div className={'max-w-xl m-auto'}>
        {isLoading && <LoadingOverlay zIndex={1000} visible overlayProps={{ radius: 'sm', blur: 2 }} />}
        <Stack>
          {getModuleNodes(data)}
          <ReportFooter />
        </Stack>
        <RightFloating>
          <Stack>
            <FloatingButton
              color={'green'}
              label={'历史报告'}
              onClick={() => {
                navigate('/orders')
              }}
            />
            <FloatingButton
              color={'yellow'}
              label={'分享报告'}
              onClick={() => {
                setShareVisible(true)
              }}
            />
          </Stack>
        </RightFloating>
        <Share shareVisible={shareVisible} setShareVisible={setShareVisible} />
      </div>
    </Watermark>
  )
}
