import './App.css'
import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import Success from './Invite/components/Success'
import '@mantine/core/styles.css'
import QueryReport from './QueryReport'
import Report from './Report'
import Invite from './Invite'
import Orders from './Orders'
import Login from './Login'
import Home from './Home'
import Sample from './Sample'
import Recharge from './Recharge'
import RechargeSuccess from './Recharge/components/RechargeSuccess'
import Payment from './Payment'
import Faceid from './Faceid'

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/queryReport" element={<QueryReport />} />
      <Route path="/reports/:orderId" element={<Report />} />
      <Route path="/reportSamples/:templateId" element={<Sample />} />
      <Route path="/success" element={<Success />} />
      <Route path="/invite" element={<Invite />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/example" element={<Report />} />
      <Route path="/login" element={<Login />} />
      <Route path="/recharge" element={<Recharge />} />
      <Route path="/rechargeSuccess" element={<RechargeSuccess />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/faceid" element={<Faceid />} />
    </Routes>
  )
}

export default App
