import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { getDetectInfo } from '../api/orderService'
import { CheckCircleFill, CloseCircleFill, ExclamationCircleFill } from 'antd-mobile-icons'

export default function Faceid() {
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('orderId') || ''
  const [loading, setLoading] = useState<boolean>(true)
  const [verificationStatus, setVerificationStatus] = useState<string>('')
  const navigate = useNavigate()

  useEffect(() => {
    if (orderId === '') {
      navigate('/login')
      return
    }

    const verifyFaceId = async () => {
      try {
        setLoading(true)
        const result = await getDetectInfo({ orderId })
        if (result.errorCode === 0) {
          setVerificationStatus('success')
          setTimeout(() => {
            navigate(
              '/payment?orderId=' +
                orderId +
                '&templateName=' +
                result?.templateName +
                '&templatePrice=' +
                result?.templatePrice
            )
          }, 1000)
        } else {
          setVerificationStatus('failed')
        }
      } catch (error) {
        setVerificationStatus('error')
      } finally {
        setLoading(false)
      }
    }
    verifyFaceId().then()
  }, [orderId, navigate])

  const renderStatusContent = () => {
    switch (verificationStatus) {
      case 'success':
        return (
          <div className="flex flex-col items-center">
            <CheckCircleFill className="text-green-600 text-5xl mb-2" />
            <span className="text-green-600 text-lg">人脸识别核验成功</span>
          </div>
        )
      case 'failed':
        return (
          <div className="flex flex-col items-center">
            <CloseCircleFill className="text-red-600 text-5xl mb-2" />
            <span className="text-red-600 text-lg">人脸识别核验失败，请重新扫码</span>
          </div>
        )
      case 'error':
        return (
          <div className="flex flex-col items-center">
            <ExclamationCircleFill className="text-red-600 text-5xl mb-2" />
            <span className="text-red-600 text-lg">核验过程出现错误，请重试</span>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center">
        {loading ? (
          <>
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-500 mx-auto"></div>
            <div className="mt-4 text-gray-600 text-lg">正在核验人脸识别结果...</div>
          </>
        ) : (
          renderStatusContent()
        )}
      </div>
    </div>
  )
}
